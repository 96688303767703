import React from 'react'
import { graphql } from 'gatsby'
import { Flex, Text } from 'rebass/styled-components'

import { PropTypes } from 'prop-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import SectionWithBackground from '../components/section-with-background'
import Container from '../components/container'
import SectionTitle from '../components/section-title'
import ButtonLink from '../components/button-link'
import Gallery from '../components/gallery'

const HouseTypeTemplate = (props) => {
  const {
    title,
    size,
    priceFrom,
    featuredImages,
  } = props.data.allMarkdownRemark.edges[0].node.frontmatter
  const contactImage = props.data.contactImage.childImageSharp.fluid
  const price = new Intl.NumberFormat('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
  }).format(priceFrom)

  return (
    <Layout hasHero={false}>
      <SEO title={title} />

      <Section variant="light">
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2">Projekt rodinného domu {title}</SectionTitle>

            <SectionTitle
              dangerouslySetInnerHTML={{
                __html: `Plocha domu ${size} ㎡<br />Cena od ${price}`,
              }}
              as="h3"
              fontSize={3}
            />

            <Text
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
              }}
            >
              Cena obsahuje{' '}
              <strong>zpracování prováděcí projektové dokumentace</strong>
              ,&nbsp;
              <strong>vyřízení stavebního povolení</strong>
              &nbsp;a <strong>výstavbu rodinného domu „na klíč“</strong>.
              <br />
              <br />V případě výstavby rodinného domu na našich pozemcích tyto
              nabízíme za zvýhodněnou cenu <strong>1&nbsp;426,-</strong> Kč za
              ㎡ včetně DPH 15 %.
              <br />
              <br />
              Projekty jsou zpracovány ve standardu 2019.
            </Text>
          </Flex>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle as="h2">
            Vizualizace, pohledy, půdorysy a informace
          </SectionTitle>

          {featuredImages && <Gallery images={featuredImages} />}
        </Container>
      </Section>

      <SectionWithBackground fluid={contactImage} variant="dark" hasOverlay>
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2" color="white">
              Zaujal Vás tento projekt?
            </SectionTitle>

            <Text
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
                mb: 4,
              }}
            >
              Neváhejte a kontaktujte nás pro bližší informace.
            </Text>

            <ButtonLink variant="white" to="/kontakt">
              Kontaktujte nás
            </ButtonLink>
          </Flex>
        </Container>
      </SectionWithBackground>
    </Layout>
  )
}

HouseTypeTemplate.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number,
  priceFrom: PropTypes.number,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    backgroundImage: PropTypes.object,
    floorplanImage: PropTypes.object,
    contactImage: PropTypes.object,
  }),
}

export default HouseTypeTemplate

export const pageQuery = graphql`
  query HouseTypeTemplateQuery($slug: String) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      limit: 1000
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            size
            priceFrom
            featuredImages {
              id
              childImageSharp {
                original {
                  src
                }
                fluid(quality: 85, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "typ-a.jpg" }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    floorplanImage: file(relativePath: { eq: "floorplan.png" }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contactImage: file(relativePath: { eq: "landscape.jpg" }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
